body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  /* font-family: 'Noto Sans', sans-serif; */
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*
.ItemBanner1:hover {
  background-color: rgba(204,57,49,0.5);
  -webkit-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
}

.ItemBanner2:hover {
  background-color: rgba(3,97,48,0.5);
  -webkit-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
}

.ItemBanner3:hover {
  background-color: rgba(245,123,32,0.5);
  -webkit-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
}
*/
.ForaBotoes {
  display: block;
  width: 165px;
}

.BtWhatsapp {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-image: url("Images/bt_whatsapp.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.BtWhatsapp:hover {
  width: 70px;
  height: 70px;
}

.Ancora {
  position: absolute;
  margin-top: -150px;
}


#rd-tqCy8gMKZ9neYRS8wX-DDw {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 0!important;
  background: transparent url("./Images/whatsapp.png") center center no-repeat;
  z-index: 900;
  box-shadow: none;
  outline: none;
  width: 60px !important;
  height: 61px !important;
  background-size: 60px 60px !important;
  cursor: pointer;
  border: 0 !important;
  /* display: none; */
}

.floating-button.floating-button--close .bricks--section {
  display: none !important;
}

.floating-button.floating-button--close .bricks--component-popup {
  box-shadow: none !important;
}

.floating-button.floating-button--close .bricks--close--button {
  display: none;
}

#rd-tqCy8gMKZ9neYRS8wX-DDw.shake {
  animation: shake 1s infinite;
}

@keyframes shake {
  0% {
  transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
  transform: translate(1px, 1px) rotate(0deg);
  }
  55% {
  transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
  transform: translate(-3px, 0px) rotate(1deg);
  }
  65% {
  transform: translate(3px, 2px) rotate(0deg);
  }
  70% {
  transform: translate(1px, -1px) rotate(1deg);
  }
  75% {
  transform: translate(-1px, 2px) rotate(-1deg);
  }
  80% {
  transform: translate(-3px, 1px) rotate(0deg);
  }
  85% {
  transform: translate(3px, 1px) rotate(-1deg);
  }
  90% {
  transform: translate(-1px, -1px) rotate(1deg);
  }
  95% {
  transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
  transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dropzone {
  width: 111%;
  border: 1px solid #00b4ff;
  background-color: transparent;
  border-radius: 15px;
  margin-top: 15px;
  color: #01e890;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
  margin-left: -10px;
}

.BotaoLimpar {
  color: #fff;
  margin-top: 20px;
  margin-left: -8px;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 500;
}

.Upload {
  color: #fff;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 500;
}

img {
  /* width: 100%;
  height: auto; */
}

.FormCadastrar{
  margin-bottom: -50px;
}

.css-mic178-MuiLinearProgress-root {
  width: 110% !important;
}


input[type="file"] {
  display: none;
}


.MensagemAtencao {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
}

.MensagemItem {
  font-weight: 600;
  font-size: 17px;
}

.BotaoAcessarBD {
  width: 110px;
  background-color: #000;
  border: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  height: 37px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
}


.AlertaJs {
  width: 445px;
  background-color: #3b3b3b;
  height: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
}

.BotaoAcessarBD {
  width: 110px;
  background-color: #000;
  border: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  height: 37px;
  border-radius: 8px;
  cursor: pointer;
}

.AlertaJs p {
  color: #fff;
  text-align: center;
}


@media(max-width: 500px) {
  .MensagemAtencao {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
  }

  .MensagemItem {
    text-align: center;
  }
}

@media(max-width: 420px) {
}







.FormContato {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.InputForm {
  width: 98%;
  height: 55px;
  border: 2px solid #e2e2e2;
  margin-top: 15px;
  border-radius: 6px;
  padding-left: 2%;
  font-family: 'Noto Sans';
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
}

.c_mensagem {
  width: 98%;
  height: 160px;
  border: 2px solid #e2e2e2;
  margin-top: 15px;
  padding-left: 2%;
  padding-top: 2%;
  font-weight: 500;
  font-family: 'Noto Sans', sans-serif;
  border-radius: 6px;
  font-size: 16px;
}



.FormSolicite {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.InputSolicita {
  height: 45px;
  border: 2px solid #e2e2e2;
  border-radius: 7px;
  margin-top: 15px;
  padding-left: 2%;
  font-family: 'Noto Sans', sans-serif;
}

.TextAreaSolicita {
  height: 155px;
  margin-top: 15px;
  border: 2px solid #e2e2e2;
  border-radius: 7px;
  padding-left: 2%;
  padding-top: 2%;
  font-family: 'Noto Sans', sans-serif;
}







.slick-initialized .slick-slide {
  display: block;
}

.slick-list {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100% !important;
  margin: 0 auto !important;
}

.SlickFotos .slick-prev {
  top: 50% !important;
}

.SlickFotos .slick-next {
  top: 50% !important;
}

.slick-slider {
  width: 100% !important;
  margin: 0 auto;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-next {
  right: 30px !important;
  width: 35px !important;
  height: 35px !important;
}

.slick-prev {
  left: 30px !important;
  width: 35px !important;
  height: 35px !important;
}

.slick-prev, .slick-next {
    z-index: 2;
}

.slick-prev:before, .slick-next:before {
  font-size: 29px !important;
  opacity: 1 !important;
}

.SliderBanner2 {
  display: none !important;
}

.SliderCasa2 {
  display: none !important;
}

.SliderCasa1 {
  width: 1500px !important;
}

.SliderBanner1  {
  padding-top: 125px;
}

.SliderEvento .slick-list {
  width: 1034px !important;
}


@media (max-width: 1150px) {
  .Plantas {
    width: 100%;
  }
} 


@media (max-width: 1045px) {
}

@media (max-width: 1640px) {
  .SliderBanner1 {
    padding-top: 76px;
}
}

@media (max-width: 1520px) {
  .SliderCasa1 {
    width: 1100px !important;
  }
}


@media (max-width: 1240px) {
  .SliderEvento .slick-list {
    width: 690px !important;
  }
}

@media (max-width: 1120px) {
  .SliderCasa1 {
    width: 800px !important;
  }
}

@media(max-width: 850px) {
  .SliderCasa2 {
    display: block !important;
  }  
  .SliderCasa1 {
    display: none !important;
  }

  .SliderEvento .slick-list {
    width: 486px !important;
  }

  .ForaBotoes {
    width: 160px;
  }
}

@media(max-width: 800px) {
  .SliderBanner2 {
    display: block !important;
    padding-top: 90px;
  }  
  
  .SliderBanner1 {
    display: none !important;
  }
}

@media(max-width: 630px) {
  /*
  .ItemBanner1 {
    background-color: rgba(204,57,49,0.5);
    -webkit-transition: background-color 600ms linear;
    -ms-transition: background-color 600ms linear;
    transition: background-color 600ms linear;
  }
  
  .ItemBanner2 {
    background-color: rgba(3,97,48,0.5);
    -webkit-transition: background-color 600ms linear;
    -ms-transition: background-color 600ms linear;
    transition: background-color 600ms linear;
  }
  
  .ItemBanner3 {
    background-color: rgba(245,123,32,0.5);
    -webkit-transition: background-color 600ms linear;
    -ms-transition: background-color 600ms linear;
    transition: background-color 600ms linear;
  }
  */
}


@media(max-width: 600px) {
  .SliderEvento .slick-list {
    width: 80% !important;
  }

  .slick-list {
    width: 100%;
  }

  .slick-next {
    right: 10px !important;
    width: 20px !important;
    height: 20px !important;
  }
  
  .slick-prev {
    left: 10px !important;
    width: 20px !important;
    height: 20px !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 630px) {
  .ForaBotoes {
    margin-left: 30px;
    display: block;
    width: 127px;
  }
}

@media screen and (max-width: 430px) {


	.BtWhatsapp {

		bottom: 10px;

		right: 10px;

		width: 35px;

		height: 35px;

	}



	.BtWhatsapp:hover {

		width: 50px;

		height: 50px;

	}

}

@media(max-width: 380px) {
  .SliderBanner2 {
    padding-top: 55px;
  }  
}
